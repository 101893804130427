import AbstractAPI from '@/services/api/AbstractAPI';

export default class AuthApi extends AbstractAPI {

  constructor(client, options) {
    super(client, options);
  }

  async authenticate(token) {
    return this.makeRequest('/authenticate', 'post', {token});
  }

  async authenticateEnquiry(quoteCode) {
    return this.makeRequest('/authenticate-enquiry', 'post', {quoteCode});
  }
  async authorize() {
    return this.makeRequest('/authorize', 'get');
  }

  async clear() {
    return this.makeRequest('/log-out', 'get');
  }

  async sendLoginEmail(email) {
    return this.makeRequest('/send-link', 'get', {params: {email}});
  }
}
