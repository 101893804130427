import {defineStore} from 'pinia';
import {useApi} from "@/services/api";

const initialAppState = {
  busy: false,
  localBusy: false,
  authenticated: false,
}

const { authApi } = useApi();

export const useAppStore = defineStore('app',{
  persist: true,
  state: () => ({...initialAppState}),
  actions: {
    setBusy(value) {
      this.busy = value;
    },
    setLocalBusy(value) {
      this.localBusy = value;
    },
    setAuthenticated(value) {
      this.authenticated = value;
    },
    authorize() {
      return authApi.authorize()
        .then(() => {this.setAuthenticated(true); this.setBusy(false)})
        .catch(() => {this.setAuthenticated(false); this.setBusy(false)})
    },
    authenticate(token) {
      return authApi.authenticate(token)
        .then(() => {this.setAuthenticated(true); this.setBusy(false)})
        .catch(() => {this.setAuthenticated(false); this.setBusy(false)})
    },
    authenticateEnquiry(quoteCode) {
      return authApi.authenticateEnquiry(quoteCode)
        .then(() => {this.setAuthenticated(true); this.setBusy(false)})
        .catch(() => {this.setAuthenticated(false); this.setBusy(false)})
    },
    logOut() {
      return authApi.clear()
        .then(() => {this.$reset(initialAppState); this.setBusy(false)})
        .catch(() => {this.$reset(initialAppState); this.setBusy(false)})
    },
    async updateGlobals() {

    },
    async sendLoginEmail(email) {
      return authApi.sendLoginEmail(email)
        .then(() => {this.setBusy(false)})
        .catch(error => console.log(error));
    }
  }
});
