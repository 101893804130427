<script>
export default {
  name: "InPageNavigation",
  props: {
    backLink: {
      type: String,
      default: '/'
    },
  },
  setup(props) {
    const navType = (props.backLink === null) ? 'action' : 'link';

    return {navType}
  }
}
</script>

<template>
  <div class="row in-page-nav">
    <div class="col d-flex justify-content-start align-items-center">
      <router-link v-if="navType === 'link'" class="in-page-nav-link" :to="backLink">
        <font-awesome-icon class="selector-chevron me-3" icon="fa-solid fa-chevron-left" />
      </router-link>
      <a v-else class="in-page-nav-link cursor-pointer" @click.stop.prevent="$emit('back-action')">
        <font-awesome-icon class="selector-chevron me-3" icon="fa-solid fa-chevron-left" />
      </a>
    </div>
  </div>
</template>

<style scoped>

</style>
