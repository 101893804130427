import DashboardView from "@/views/private/DashboardView";
import HomeView from "@/views/private/HomeView";
import ContactView from "@/views/private/ContactView";
import MyQuotesView from "@/views/private/MyQuotesView.vue";
import ProfileView from "@/views/private/ProfileView.vue";
import QuoteDetailsView from "@/views/private/QuoteDetailsView.vue";
import PrivacyView from "@/views/public/PrivacyView.vue";
import TermsView from "@/views/public/TermsView.vue";

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ "@/views/public/LoginView"),
    meta: {
      public: true,
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView,
    meta: {
      public: true,
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView,
    meta: {
      public: true,
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      public: false,
    },
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        meta: {
          public: false,
        }
      },
      {
        path: 'my-quotes',
        name: 'myQuotes',
        component: MyQuotesView,
        meta: {
          public: false,
        },
      },
      {
        path: 'my-quotes/:id',
        name: 'quoteDetails',
        component: QuoteDetailsView,
        meta: {
          public: false,
        }
      },
      {
        path: 'contact',
        name: 'contact',
        component: ContactView,
        meta: {
          public: false,
        }
      },
      {
        path: 'profile',
        name: 'profile',
        component: ProfileView,
        meta: {
          public: false,
        }
      },
    ],
  },
  {
    path: '/qualify/:id',
    name: 'self-qualify',
    component: () => import(/* webpackChunkName: "self-qualify" */ "@/views/private/SelfQualifyView.vue"),
    meta: {
      public: false,
    }
  }
];
