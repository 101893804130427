<template>
  <div class="row mt-5">
    <div class="col-12 col-lg-6 pe-4">
      <card-block :arrow="'top'" :arrow-link="'/my-quotes'">
        <div class="w-100">
          <router-link to="/my-quotes" class="block-title mt-0">
            {{ $t('My quotes') }}
          </router-link>
          <enquiry-list-small />
        </div>
      </card-block>
    </div>
    <div class="col-12 col-lg-6 ps-4">
      <card-block :arrow="'top'" :arrow-link="'/profile'">
        <div class="w-100">
          <router-link to="/profile" class="block-title mt-0">
            {{ $t('My profile') }}
          </router-link>
          <profile-details-blocks />
        </div>
      </card-block>
    </div>
  </div>
</template>

<script>
import {useAppStore} from "@/store/appStore";
import CardBlock from "@/components/CardBlock.vue";
import EnquiryListSmall from "@/components/lists/EnquiryListSmall.vue";
import ProfileDetailsBlocks from "@/components/ProfileDetailsBlocks.vue";

export default {
  name: "HomeView",
  components: {ProfileDetailsBlocks, EnquiryListSmall, CardBlock},
  setup() {
    const store = useAppStore();
    store.setBusy(false);
  }
}
</script>

<style scoped>

</style>
