<template>
  <div :class="['enquiry-dashboard', {'enquiry-dashboard--brand': selfQualification}, 'container-fluid', 'p-0', 'pb-5']">
    <dashboard-navigation />
    <jumbo-block v-if="home" :text="$t('Welcome back')" :name="user?.firstName" />
    <div class="container">
      <div v-if="appStore.busy">
        loading
      </div>
      <router-view />
    </div>
  </div>
  <div v-if="selfQualification" class="container-fluid pt-3 pt-md-5">
    <div class="row">
      <div class="col-12 text-center header-secondary">
        {{ $t('Our Brands') }}:
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12 d-flex justify-content-evenly flex-wrap logo-block flex-column flex-md-row mb-5 align-items-center">
        <img src="@/assets/gm-logo.png" alt="logo">
        <img src="@/assets/bg-logo.png" alt="logo">
        <img src="@/assets/sg-logo.png" alt="logo">
        <img src="@/assets/hhq-logo.png" alt="logo">
        <img src="@/assets/market-inspector-logo.png" alt="logo">
      </div>
    </div>
  </div>
  <app-footer />
</template>
<script>

import {useAppStore} from "@/store/appStore";
import DashboardNavigation from "@/components/nav/DashboardNavigation.vue";
import JumboBlock from "@/components/JumboBlock.vue";
import {useRoute} from "vue-router";
import {computed} from "vue";
import {storeToRefs} from "pinia";
import {useUserStore} from "@/store/userStore";
import AppFooter from "@/components/nav/AppFooter.vue";
import {useColorWatcher} from "@/composables/useColorWatcher";
import {useAuthWatcher} from "@/composables/useAuthWatcher";

export default {
  name: "App",
  components: {AppFooter, JumboBlock, DashboardNavigation},
  setup() {
    const appStore = useAppStore();
    const userStore = useUserStore();
    const route = useRoute();
    const routeName = computed(() => route.name);
    const home = computed(() => route.name === 'home');
    const selfQualification = computed(() => route.name === 'self-qualify');
    const { user } = storeToRefs(userStore);

    useColorWatcher(routeName);
    useAuthWatcher();

    return {appStore, home, selfQualification, user};
  },
}
</script>
