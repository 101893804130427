import Axios from 'axios';
import AuthApi from "@/services/api/auth";
import EnquiryApi from "@/services/api/enquiry";
import UserApi from "@/services/api/user";
import FormApi from "@/services/api/form";
import AddressApi from "@/services/api/address";
import PageApi from "@/services/api/page"

const API_URL = process.env.VUE_APP_API_URL;
const VERSION = 'v1';

// eslint-disable-next-line new-cap
const client = new Axios.create({
  withCredentials: true,
  baseURL: API_URL,
});
// eslint-disable-next-line new-cap
const pagesClient = new Axios.create({
  withCredentials: true,

});

export function useApi() {
  return {
    authApi: new AuthApi(
      client,
      {
        version: VERSION,
      },
    ),
    enquiryApi: new EnquiryApi(
      client,
      {
        version: VERSION,
      },
    ),
    userApi: new UserApi(
      client,
      {
        version: VERSION
      },
    ),
    formApi: new FormApi(
      client,
      {
        version: VERSION
      },
    ),
    addressApi: new AddressApi(
      client,
      {
        version: VERSION
      },
    ),
    pageApi: new PageApi(
      pagesClient,
      {
        version: VERSION
      },
    )
  };
}
