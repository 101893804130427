<template>
  <router-view />
</template>

<script>
import {useAppStore} from "@/store/appStore";

export default {
  name: "DashboardView",
  setup() {
    const store = useAppStore();
    store.setBusy(false);
  }
}
</script>

<style scoped>

</style>
