<template>
  <div :class="['navbar','navbar-expand-lg', 'navbar-dark', 'bg-theme-primary', {qualification: isSelfQualification} ]">
    <div :class="[isSelfQualification ? 'qualification__container container' : 'container']">
      <button
        :class="['navbar-toggler', {qualification__toggler: isSelfQualification}]"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarItemsToggler"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <img v-if="isSelfQualification && siteLogo !== null" class="logo ms-2 navbar__logo" :src="siteLogo">
    </div>
    <div id="navbarItemsToggler" :class="['collapse', 'navbar-collapse', {navbar__hide: isSelfQualification}]">
      <ul :class="['navbar-nav', 'ms-auto', {navbar__hide: isSelfQualification} ]">
        <li class="nav-item">
          <router-link to="/" class="nav-link">
            {{ $t('Nav.Home') }}
          </router-link>
        </li>
        <li v-if="authenticated" class="nav-item">
          <router-link to="/my-quotes" class="nav-link">
            {{ $t('Nav.My Quotes') }}
          </router-link>
        </li>
        <li v-if="authenticated" class="nav-item">
          <router-link to="/profile" class="nav-link">
            {{ $t('Nav.Profile') }}
          </router-link>
        </li>
        <li class="nav-item">
          <a v-if="authenticated" class="nav-link cursor-pointer" @click.stop.prevent="logOut">{{ $t('Nav.Log Out') }}</a>
          <router-link v-else to="/login" class="nav-link" @click.stop.prevent="login">
            {{ $t('Nav.Log In') }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <login-block @interface="setLoginInterface" />
</template>

<script>
import {computed, ref, watch} from "vue";
import {useAppStore} from "@/store/appStore";
import {useRouter, useRoute} from "vue-router";
import LoginBlock from "@/components/LoginBlock.vue";
import {useQualificationSite} from "@/composables/useQualificationSite";
import {getLogoFromSite} from "@/services/sites";

export default {
  name: "DashboardNavigation",
  components: {LoginBlock},
  setup() {
    const appStore = useAppStore();
    const router = useRouter();
    const route = useRoute();
    const loginInterface = ref(null);
    const enquirerSite = useQualificationSite();

    const authenticated = computed(
      () => appStore.authenticated,
    )
    function logOut() {
      appStore.logOut().then(
        () => router.push({name: 'login'}),
      );
    }

    function login() {
      loginInterface.value.show();
    }

    function setLoginInterface(intf) {
      loginInterface.value = intf;
    }

    const routeName = computed(() => route.name);

    const isSelfQualification = computed(() => routeName.value === 'self-qualify');
    const siteLogo = computed(() => enquirerSite.value !== null ? getLogoFromSite(enquirerSite.value) : null);

    watch(routeName, async (newName) => {
      if (newName === 'login') {
        loginInterface.value.show();
      }
    });

    return {logOut, authenticated, isSelfQualification, enquirerSite, setLoginInterface, login, siteLogo}
  }
}
</script>

<style scoped lang="scss">
  .nav {
    &-link {
      text-wrap: nowrap;
    }

    &__thin {
      height: 60px;
    }
  }

  .logo {
    max-width: 200px;
  }
</style>
