<script>
import {useEnquiryStore} from "@/store/enquiryStore";
import {storeToRefs} from "pinia";
import EnquiryDetails from "@/components/EnquiryDetails.vue";
import CardBlock from "@/components/CardBlock.vue";

export default {
  name: "EnquiryListLarge",
  components: {CardBlock, EnquiryDetails},
  setup() {
    const enquiryStore = useEnquiryStore();
    const {getEnquiries} = storeToRefs(enquiryStore);
    enquiryStore.fetchEnquiries();

    return {getEnquiries};
  }
}
</script>

<template>
  <div class="col-12 d-flex flex-column">
    <card-block v-for="enquiry in getEnquiries()" :key="enquiry.quoteCode" arrow="center">
      <enquiry-details :enquiry="enquiry" />
    </card-block>
  </div>
</template>

<style scoped>

</style>
