import AbstractAPI from '@/services/api/AbstractAPI';

export default class UserApi extends AbstractAPI {
    constructor(client, options) {
        super(client, options);
    }

    async profile() {
        return this.makeRequest('/user', 'get');
    }

    async updateProfile(profile) {
        return this.makeRequest('/user', 'put', profile)
    }

    async getPhoneNumberVerificationCode(verificationType, phoneNumber) {
        return this.makeRequest('/phone-verification/' + verificationType, 'post', { phoneNumber });
    }

    async verifyPhoneNumberWithCode(verificationCode) {
        return this.makeRequest('/phone-verification/verify', 'post', { verificationCode })
    }
}