import AbstractAPI from '@/services/api/AbstractAPI';

export default class UserApi extends AbstractAPI {
    constructor(client, options) {
        super(client, options);
    }

    async getFromEnquiry(quoteCode, version) {
        return this.makeRequest(
          `/form/from-enquiry/${quoteCode}`,
          'get',
          (version) ? {params: {version}} : {}
        );
    }
}
