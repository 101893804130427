import {defineStore} from 'pinia';
import {useApi} from "@/services/api";

const initialEnquiryState = {
  enquiries: [],
  selectedEnquiry: null,
}

const { enquiryApi } = useApi();

export const useEnquiryStore = defineStore('enquiry',{
  persist: true,
  state: () => ({...initialEnquiryState}),
  getters: {
    getEnquiries: (state) =>
      (limit = null) => {
        const ordered = state.enquiries.sort((a, b) => {
          const aDate = new Date(a.requestDate);
          const bDate = new Date(b.requestDate);
          return (aDate < bDate) ? 1 : (aDate > bDate) ? -1 : 0;
        });

        return ordered.slice(0, limit ?? ordered.length);
      }
  },
  actions: {
    fetchEnquiries() {
      this.enquiries = [];
      return enquiryApi.list()
        .then(response => {this.enquiries = [...response.data.data]})
        .catch(error => {console.log(error)});
    },
    fetchEnquiry(quoteCode) {
      this.selectedEnquiry = null;
      return enquiryApi.fetch(quoteCode)
        .then(response => {
          this.selectedEnquiry = response.data.data.enquiry;
          this.selectedEnquiry.companyNames = response.data.data.companyTradingNames
        })
        .catch(error => {console.log(error)});
    }
  }
});
