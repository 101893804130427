import { createRouter, createWebHistory } from 'vue-router'
import {routes} from "@/router/routes";
import {useAppStore} from "@/store/appStore";
import {trackPageView, setCustomUrl} from "@/services/monitoring/analytics";

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeResolve((to, from, next) => {
  routeHandler.appStore = useAppStore();
  if (routeHandler.isOpen(to)) {
    if (!routeHandler.appStore.authenticated && (to.query.at || to.query.qcat) && to.name === "login") {
      routeHandler.handleAuthorization(from, to, next);
    } else {
      next();
    }
  } else {
    routeHandler.handleAuthorization(from, to, next);
  }
});

router.afterEach((to) => {
    setCustomUrl(to.fullPath);
    trackPageView();
})

const routeHandler = {
  appStore: null,
  isOpen(route) {
    return route.matched.some(record => record.meta.public);
  },
  sameRoute(from, to, callback) {
    if (from.name === to.name) {
      callback();
      return true;
    }
    return false;
  },

  isSameParentRoute(from, to) {
    const fromMatched = from.matched;
    const toMatched = to.matched;
    let commonDepth = 0;
    while (
      fromMatched[commonDepth] &&
      toMatched[commonDepth] &&
      fromMatched[commonDepth].path === toMatched[commonDepth].path
      ) {
      commonDepth++;
    }
    if (commonDepth > 0 && (fromMatched[commonDepth - 1].path === toMatched[commonDepth - 1].path)) {
        return true;
    }

    return to.fullPath.startsWith(from.fullPath) || from.fullPath.startsWith(to.fullPath);
  },

  handleAuthorization(from, to, callback) {
    if (!this.appStore.authenticated && (to.query.at || to.query.qcat)) {
      this.appStore.setBusy(true);
      if (to.query.at) {
        this.authenticateUser(callback, to.query.at);
      } else if (to.query.qcat) {
        this.authenticateEnquiry(callback, to.query.qcat, to.query.v);
      }
    } else if (!this.sameRoute(from, to, callback)) {
      if (this.isSameParentRoute(from, to)) {
        this.appStore.setLocalBusy(true);
      } else {
        this.appStore.setBusy(true);
      }
      this.authorizeUser(callback);
    }
  },
  authenticateUser(callback, authToken) {
    this.appStore.authenticate(authToken).then(() => {
      if (this.appStore.authenticated === false) {
        this.logOut(callback);
      } else {
        this.allow(callback);
      }
    });
  },
  authenticateEnquiry(callback, quoteCode, version) {
    this.appStore.authenticateEnquiry(quoteCode).then(() => {
      if (this.appStore.authenticated === false) {
        this.logOut(callback);
      } else {
        this.allow(callback, {name:"self-qualify", params: {id: quoteCode}, query: {v: version}});
      }
    });
  },
  authorizeUser(callback) {
    this.appStore.authorize().then(() => {
      if (this.appStore.authenticated === false) {
        this.logOut(callback);
      } else {
        this.allow(callback);
      }
    });
  },
  logOut(callback) {
    this.appStore.logOut().then(() => {
      callback({name:"login"});
    });
  },
  allow: function(callback, route = null) {
    this.appStore.updateGlobals().then(() => {
      route ? callback(route) : callback();
      this.appStore.setBusy(false);
    });
  },
}

export default router;
