import { useFormStore } from "@/store/formStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";

export function useQualificationSite() {
    const formStore = useFormStore();
    const { enquiry } = storeToRefs(formStore);
    const siteNo = computed(() => enquiry.value?.siteNo ?? null);

    return siteNo;
}