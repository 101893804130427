import {captureError} from "@/services/userMonitoring";
/* global _paq */

export function trackEvent(category, action, name, stepsTaken) {
    push('trackEvent', category, action, name, stepsTaken);
}

export function setCustomUrl(url) {
    push('setCustomUrl', url);
}

export function trackPageView() {
    push('trackPageView');
}

function push(type, ...args) {
    try {
        if (_paq !== undefined) {
            _paq.push([type, ...args]);
        }
    } catch (e) {
        captureError(e);
    }
}