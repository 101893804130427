<script>
import EnquiryListLarge from "@/components/lists/EnquiryListLarge.vue";
import InPageNavigation from "@/components/nav/InPageNavigation.vue";

export default {
  name: "MyQuotesView",
  components: {InPageNavigation, EnquiryListLarge}
}
</script>

<template>
  <div>
    <in-page-navigation :back-link="'/'" />
    <div class="row">
      <div class="col">
        <div class="block-title">
          {{ $t('My quotes') }}
        </div>
      </div>
    </div>
    <div class="row">
      <enquiry-list-large />
    </div>
  </div>
</template>

<style scoped>

</style>
