import AbstractAPI from '@/services/api/AbstractAPI';

export default class AddressApi extends AbstractAPI {

  constructor(client, options) {
    super(client, options);
  }

  async findList(postcode, countryCode) {
    return this.makeRequest('/address', 'get', {params: {'postcode': postcode, 'countryCode': countryCode}});
  }

  async getStructured(id) {
    return this.makeRequest('/structured-address', 'get', {params: {id}});
  }
}
