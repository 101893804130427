export default class AbstractAPI {
  constructor(client, options, baseUrl = '') {
    this.version = (options.version && options.version.length > 0) ? options.version : 'v1';
    this.client = client;
    this.baseUrl = baseUrl;
  }

  async makeRequest(endpoint, method = 'get', options = {}) {
    const validMethods = new Set(['get', 'post', 'put', 'delete']);

    if (!validMethods.has(method)) {
      throw new TypeError(`Invalid HTTP method "${method}" must be one of ${Array.from(validMethods).join(', ')}`);
    }

    let url = this.version + this.baseUrl + endpoint;
    return await this.client[method](url, options);
  }

  async makeFileSystemRequest(endpoint, method = 'get', options = {}) {
    const validMethods = new Set(['get']);

    if (!validMethods.has(method)) {
      throw new TypeError(`Invalid HTTP method "${method}" must be one of ${Array.from(validMethods).join(', ')}`);
    }

    return await this.client[method](endpoint, options);
  }
}
