<script>
import { ref, onMounted } from 'vue'
import {Modal} from 'bootstrap'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
export default {
  name: "PopUp",
  components: {FontAwesomeIcon},
  setup(props, {emit}) {
    const modalWrapper = ref(null);
    const modal = ref(null);

    onMounted(() => {
      modal.value = new Modal(modalWrapper.value, {backdrop: 'static'});
      emitInterface();
    });

    function emitInterface() {
      emit('interface', {
        show: () => {modal.value.show()},
        hide: () => {modal.value.hide()},
      });
    }

    return {modalWrapper};
  }
}
</script>

<template>
  <div ref="modalWrapper" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-fullscreen-xl-down">
      <div class="modal-content">
        <div class="modal-header align-items-end justify-content-end">
          <a type="button" data-bs-dismiss="modal" aria-label="Close" class="close-button">
            <font-awesome-icon icon="fa-solid fa-remove" />
          </a>
        </div>
        <div class="modal-body">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
