<script>
import {computed} from "vue";

export default {
  name: "EnquiryStatusIcon",
  props: {
    enquiry: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const icon = computed(() => {
      if (props.enquiry.answerSetCSV.split(',').includes('58')
        || props.enquiry.answerSetCSV.split(',').includes('1287')) {
        return 'fa-circle-check';
      } else {
        return 'fa-triangle-exclamation';
      }
    });

    const colour = computed(() => {
      if (props.enquiry.answerSetCSV.split(',').includes('58')
        || props.enquiry.answerSetCSV.split(',').includes('1287')) {
        if (props.enquiry.leads.length > 0) {
          return 'icon--green';
        } else {
          return 'icon--orange';
        }
      } else {
        return 'icon--red';
      }
    });

    return {icon, colour};
  }
}
</script>

<template>
  <font-awesome-icon :class="['me-3', colour]" :icon="['fa-solid', icon]" />
</template>

<style scoped lang="scss">
.icon {
  &--green {
    color: #00B007;
  }
  &--orange {
    color: #FF7A00;
  }
  &--red {
    color: #FF0000;
  }
}


</style>
