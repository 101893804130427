import { datadogRum } from '@datadog/browser-rum';

export function init() {
    if (process.env.VUE_APP_APP_ENV !== 'dev') {
        datadogRum.init({
            applicationId: process.env.VUE_APP_DATADOG_APP_ID,
            clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
            site: 'datadoghq.eu',
            service: 'enquirer-dashboard',
            env: process.env.VUE_APP_APP_ENV,
            version: process.env.VUE_APP_VERSION,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input'
        });
    }
}

export function captureError(e, context) {
    datadogRum.addError(e, context);
}

export function setContext(context) {
    datadogRum.setUser(context);
}