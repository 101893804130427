import { createI18n } from 'vue-i18n'
import enGB, { numberFormats as enGBNumberFormats, dateTimeFormats as enGBDateTimeFormats } from './en-GB';
import svSE, { numberFormats as svSENumberFormats, dateTimeFormats as svSEDateTimeFormats } from './sv-SE';
import daDK, { numberFormats as daDKNumberFormats, dateTimeFormats as daDKDateTimeFormats } from './da-DK';
import noNO, { numberFormats as noNONumberFormats, dateTimeFormats as noNODateTimeFormats } from './no-NO';
import frFR, { numberFormats as frFRNumberFormats, dateTimeFormats as frFRDateTimeFormats } from './fr-FR';

export const i18n = createI18n({
    locale: 'en-GB',
    fallbackLocale: 'en-GB',
    legacy: false,
    messages: {
        'en-GB': enGB,
        'sv-SE': svSE,
        'da-DK': daDK,
        'no-NO': noNO,
        'fr-FR': frFR,
    },
    numberFormats: {
        ...enGBNumberFormats,
        ...svSENumberFormats,
        ...daDKNumberFormats,
        ...noNONumberFormats,
        ...frFRNumberFormats
    },
    datetimeFormats: {
        ...enGBDateTimeFormats,
        ...svSEDateTimeFormats,
        ...daDKDateTimeFormats,
        ...noNODateTimeFormats,
        ...frFRDateTimeFormats
    }
})
