<script>
import {useRouter} from "vue-router";

export default {
  name: "CardBlock",
  props: {
    arrow: {
      type: String,
      default: null,
    },
    arrowLink: {
      type: String,
      default: null,
    }
  },
  setup(props) {
    const router = useRouter();

    function navigate() {
      if (props.arrowLink !== null) {
        router.push(props.arrowLink);
      }
    }

    return {navigate}
  }
}
</script>

<template>
  <div :class="['card-block', 'd-flex', 'justify-content-between', arrow === 'top' ? 'align-items-start' : 'align-items-center']">
    <slot />
    <font-awesome-icon
      v-if="arrow !== null"
      class="selector-chevron"
      icon="fa-solid fa-chevron-right"
      @click="navigate"
    />
  </div>
</template>

<style scoped>

</style>
