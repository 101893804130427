import {defineStore} from 'pinia';
import {useApi} from '@/services/api';
import {NodeTypes} from '@/services/flow/NodeTypes';
import {useUserStore} from "@/store/userStore";
import {captureError} from '@/services/userMonitoring';

const { enquiryApi, formApi } = useApi()
const initialState = {
  enquiry: null,
  formId: null,
  versionName: null,
  flow: [],
  currentNode: null,
  stepsTaken: [],
  answers: {},
  busy: true,
}

export const useFormStore = defineStore('form', {
  persist: true,
  state: () => ({...initialState}),
  getters: {
    currentOutgoingEdges() {
      return this.flow.filter((nodeOrEdge) => {
        const isEdge = nodeOrEdge.target !== undefined && nodeOrEdge.source !== undefined;
        const isConnectedToCurrentNode = nodeOrEdge.source === this.currentNode?.id;
        return isEdge && isConnectedToCurrentNode;
      });
    },
    possibleFutureNodes() {
      return this.currentOutgoingEdges.map((edge) => edge.targetNode);
    },
    previousNode() {
      return this.stepsTaken[this.stepsTaken.length - 1];
    },
    selectedQuestionAnswers() {
      return Object.keys(this.answers).filter((id) => !isNaN(id));
    },
    address() {
      return {
        address1: this.enquiry?.address1 ?? '',
        address2: this.enquiry?.address2 ?? '',
        town: this.enquiry?.town ?? '',
        postcode: this.enquiry?.postcode ?? '',
        country: this.enquiry?.country ?? '',
      }
    },
  },
  actions: {
    clearStepHistory() {
      this.stepsTaken = [];
    },
    async loadForm(quoteCode, version) {
      this.$reset(initialState);
      Promise.all([
        formApi.getFromEnquiry(quoteCode, version)
          .then((response) => {
            this.flow = response.data.data.flow.flow;
            this.formId = response.data.data.flow.id;
            this.versionName = response.data.data.flow.name;
            this.currentNode = this.flow.find((node) => node.type === NodeTypes.Start);
          }),
        enquiryApi.fetch(quoteCode)
          .then(response => this.enquiry = response.data.data.enquiry)
      ])
      .catch(error => { captureError(error) })
      .finally(() => this.busy = false)
    },
    progressFromHandleId(handleId) {
      const nextNode = this.currentOutgoingEdges.find((edge) => edge.sourceHandle === handleId.toString())?.targetNode ?? null;
      if (nextNode !== null) {
        this.pushNodeToHistory();
        this.currentNode = nextNode;
        if (this.shouldSkipNode(this.currentNode)) {
          this.progressToNextNode();
        }
      }
    },
    progressToNextNode(nodeId) {
      let nextNode = null;
      if (nodeId !== undefined && nodeId !== null) {
        nextNode = this.flow.find((node) => node.id === nodeId);
      } else if (this.possibleFutureNodes.length === 1) {
        nextNode = this.possibleFutureNodes[0];
      }

      this.pushNodeToHistory();

      if (nextNode !== null) {
        this.currentNode = nextNode;
      }
      if (this.shouldSkipNode(this.currentNode)) {
        this.progressToNextNode();
      }
    },
    progressToPreviousNode() {
      const previousNode = this.stepsTaken.pop();
      if (previousNode !== undefined) {
        this.currentNode = previousNode;
      }
    },
    addAnswer(id, answer) {
      this.answers[id] = answer;
    },
    shouldSkipNode(node) {
      switch (node.type) {
        case 'addressConfirmation':
          return false;
      }
      return false;
    },
    phoneVerified() {
      const userStore = useUserStore();
      const verified = userStore.user.phoneVerifications
        .find((verification) => verification.telephone === this.enquiry.telephone && verification.verifiedAt === true);
      return verified !== undefined;
    },
    invalidateEnquiry(holdSustainReason) {
      return enquiryApi.invalidate(this.enquiry.quoteCode, holdSustainReason)
        .then(() => {
          this.progressToNextNode()
        })
        .catch((error) => {
          captureError(error)
        })
    },
    qualifyEnquiry() {
      const enquiryData = this.getEnquiryData();
      return enquiryApi.qualify(this.enquiry.quoteCode, enquiryData)
        .then(() => {
          this.progressToNextNode()
        })
        .catch((error) => {
          captureError(error)
        })
    },
    async saveEnquiryAnswers() {
      const enquiryData = this.getEnquiryData();
      return enquiryApi.saveEnquiryAnswers(this.enquiry.quoteCode, enquiryData)
        .then(() => {
          this.progressToNextNode()
        })
        .catch((error) => {
          captureError(error)
        })
    },
    pushNodeToHistory() {
      const hiddenNodes = ['qualifyEnquiry', 'invalidation'];
      if (!hiddenNodes.includes(this.currentNode.type)) {
        this.stepsTaken.push(this.currentNode);
      }
    },
    getAnswer(questionid) {
      return this.answers[questionid]
    },
    getEnquiryData() {
      return {
        address1: this.answers?.address?.line1 ?? this.enquiry.address1,
        address2: this.answers?.address?.line2 ?? this.enquiry.address2,
        town: this.answers?.address?.post_town ?? this.enquiry.town,
        postcode: this.answers?.address?.postcode ?? this.enquiry.postcode,
        phone: this.answers?.telephone ?? this.enquiry.telephone,
        alternatePhone: this.enquiry.alternatePhone,
        answers: this.selectedQuestionAnswers.map((id) =>
          ({
            questionId: id,
            answerAlias: this.answers[id].optionAlias,
          })
        ),
      }
    },
    async addEnquiryHistoryNote(historyNote) {
      return enquiryApi.addEnquiryHistoryNote(this.enquiry.quoteCode, historyNote)
        .then(() => {
          this.progressToNextNode()
        })
        .catch((error) => {
          captureError(error)
        })
    }
  }
});
