import {computed, watch} from "vue";
import {useUserStore} from "@/store/userStore";
import {useAppStore} from "@/store/appStore";

export function useAuthWatcher() {
  const userStore = useUserStore();
  const appStore = useAppStore();

  const authenticated = computed(
    () => appStore.authenticated,
  )

  watch(authenticated, async (status) => {
    if(status === true) {
      userStore.fetchProfile();
    }
  });
}
