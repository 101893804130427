import {defineStore} from 'pinia'
import {useApi} from "@/services/api"
import {captureError, setContext} from '@/services/userMonitoring';
import { i18n } from "@/locales";

const { userApi } = useApi()
const initialUserState = {
  user: null
}

export const useUserStore = defineStore('user', {
  persist: true,
  state: () => ({...initialUserState}),
  actions: {
    async fetchProfile() {
      return userApi.profile()
        .then(response => {
          this.user = response.data.data.user;
          setContext({ userId: this.user.id });
          i18n.global.locale.value = this.user.localeFromLatestEnquiry;
        })
        .catch(error => { captureError(error) })
    },
    async updateUser(payload) {
      return userApi.updateProfile(payload)
        .catch((error) => {
          captureError(error)
        });
    },
  }
});