import AbstractAPI from '@/services/api/AbstractAPI';

export default class EnquiryApi extends AbstractAPI {
  constructor(client, options) {
    super(client, options);
  }

  async list() {
    return this.makeRequest('/enquiry', 'get');
  }

  async fetch(quoteCode) {
    return this.makeRequest(`/enquiry/${quoteCode}`, 'get');
  }

  async qualify(quoteCode, data) {
    return this.makeRequest(`/enquiry/qualify/${quoteCode}`, 'post', { data });
  }

  async invalidate(quoteCode, holdSustainReason) {
    return this.makeRequest(`/enquiry/invalidate/${quoteCode}`, 'post', { holdSustainReason });
  }

  async saveEnquiryAnswers(quoteCode, data) {
    return this.makeRequest(`/enquiry/save-answers/${quoteCode}`, 'post', { data });
  }

  async addEnquiryHistoryNote(quoteCode, historyNote) {
    return this.makeRequest(`/enquiry/add-history-note/${quoteCode}`, 'post', { historyNote });
  }
}
