<script>
export default {
  name: "JumboBlock",
  props: {
    text: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  }
}
</script>

<template>
  <div class="container-fluid jumbo">
    <span class="jumbo__heading">
      {{ text }}, <span class="jumbo__heading__name">
        {{ name }}
      </span>
    </span>
  </div>
</template>

<style scoped lang="scss">

</style>
