import translations from './translations.json';

export const numberFormats = {
  'sv-SE': {
    currency: {
      style: 'currency',
      currency: 'EUR',
    }
  }
}

export const dateTimeFormats = {
  'sv-SE': {
    time: {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    },
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    shortWithTime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }
  }
};

export default {
  ...translations
}
