<script>
import {useUserStore} from "@/store/userStore";

export default {
  name: "ProfileDetailsBlocks",
  setup() {
    const userStore = useUserStore();

    return {
      userStore,
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12 col-md-6 py-3">
      <div class="col-form-label fw-bold">
        {{ $t('Profile.label.First name') }}:
      </div>
      <div>
        {{ userStore.user.firstName }}
      </div>
    </div>
    <div class="col-12 col-md-6 py-3">
      <div class="col-form-label fw-bold">
        {{ $t('Profile.label.Last name') }}:
      </div>
      <div>
        {{ userStore.user.lastName }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 py-3">
      <div class="col-form-label fw-bold">
        {{ $t('Profile.label.Email address') }}:
      </div>
      <div>
        {{ userStore.user.email }}
      </div>
    </div>
    <div class="col-12 col-md-6 py-3">
      <div class="col-form-label fw-bold">
        {{ $t('Profile.label.Phone number') }}:
      </div>
      <div>
        {{ userStore.user.telephone }}
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
