import AbstractAPI from '@/services/api/AbstractAPI';

export default class PageApi extends AbstractAPI {

  constructor(client, options) {
    super(client, options);
  }

  getPageContent = async function(locale = 'en-GB') {
    return this.makeFileSystemRequest(process.env.BASE_URL + `assets/json/pages_${locale}.json`, 'get');
  };
}
